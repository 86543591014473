import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConsultarArt } from './consultar-art';
import { HttpHeaders } from "@angular/common/http"; 
import { MatSort, MatTableDataSource } from '@angular/material';
/*import { ExecFileOptionsWithStringEncoding } from 'child_process';*/


@Component({
  selector: 'app-consultar-art',
  templateUrl: './consultar-art.component.html',
  styleUrls: ['./consultar-art.component.css']
})
export class ConsultarArtComponent implements OnInit {
  public  identificacion: string;
  restItems: object[]; 
  nombreUsuario: string;
  restItemsUrl = 'http://vientri.mine.nu:3000/articulos/busqueda';


  constructor(private http: HttpClient) {
    this.identificacion="";
   }

/*'id', 'cod',,'stock'*/
  displayedColumns: string[] = [ 'des', 'medida','unidad','impo'];
  dataSource = new MatTableDataSource(this.restItems);

  @ViewChild(MatSort) sort: MatSort;
 

  ngOnInit() {
    this.nombreUsuario=localStorage.getItem("nombreusuario")

  }

  OnSubmit(identificacion){
    var token=localStorage.getItem('x-access-token')
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });
    
    return this.http.get(this.restItemsUrl+"?identificador="+identificacion , {headers: reqHeader})
    .subscribe((data: ConsultarArt[]) => {
      this.restItems = data;
      this.dataSource = new MatTableDataSource(this.restItems);
      this.dataSource.sort = this.sort;
    })
  } 
}
