import { Component, OnInit,Input  } from '@angular/core';

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.css']
})
export class EncabezadoComponent implements OnInit {
  @Input()  public nombreUsuario:string;
    public nombreOpcion:string;

    public logoEmpresa: string;
    public nombreEmpresa: string;

  constructor() {
    this.nombreUsuario="Vientri";
    this.nombreEmpresa="Vientri";
    this.nombreOpcion="";
    this.logoEmpresa="../assets/data48.png";
   } 
  
   ngOnInit() {
    this.nombreUsuario=localStorage.getItem("nombreusuario")
    this.nombreEmpresa=localStorage.getItem("nombreempresa")
   /* public CadenaMixta: string;
    this.nombreUsuario = this.CadenaMixta.split(',')[1].toString()
    this.nombreOpcion = this.CadenaMixta.split(',')[2].toString()
*/
    this.logoEmpresa="../assets/"+localStorage.getItem("logoEmpresa")

  } 
}
