import{Pipe,PipeTransform} from '@angular/core'
@Pipe({
    name:"truncate"
})
export class TruncatePipe implements PipeTransform {
transform(value:string, limite: string):string{
    let limit=parseInt(limite);
    return value.length>limit ? value.substring(0,limit)+'...' : value
}
}

/*

Los pipes se pueden encadenar de modo que puedo por ejemplo


{{fecsys | date :"YYYYMMDD" | upercase }}


ademas cada pipe puede recibir parametros como el caso de este truncate

ejmp.

    {{NombreLargo | truncate:"30" | lowercase}}

    en este caso lo corto a 30 y lo pongo en minuscula

*/

