import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
  

@Component({
  selector: 'app-bcscanner',
  templateUrl: './bcscanner.component.html',
  styleUrls: ['./bcscanner.component.css']
})
export class BCScannerComponent implements AfterViewInit {
 
 /*https://www.youtube.com/watch?v=2N6MZVULgDM
 
 npm i angular2-qrscanner

 npm install --save quagga ngx-barcode-scanner

 
 */
  
  @ViewChild(BarecodeScannerLivestreamComponent)
  BarecodeScanner: BarecodeScannerLivestreamComponent;
  
  barcodeValue;
  
  ngAfterViewInit() {
      this.BarecodeScanner.start();
  }

  onValueChanges(value){
      this.barcodeValue = value.code;
  }

}
