import { Routes } from '@angular/router'
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { MenuComponent } from './menu/menu.component'
import { AuthGuard } from './auth/auth.guard';
import { EstadocanchaComponent} from './clubjuniors/estadocancha/estadocancha.component';
import { ConsultarArtComponent } from './vientri/consultar-art/consultar-art.component';
import { ConsultarPedidosComponent } from './vientri/consultar-pedidos/consultar-pedidos.component';
import { ConsultarPedidosItemComponent } from './vientri/consultar-pedidos-item/consultar-pedidos-item.component';
import { ViScannerComponent } from './vientri/vi-scanner/vi-scanner.component';

export const appRoutes: Routes = [
    { path: 'home', component: AppComponent, canActivate:[AuthGuard] }, 
    {
        path: 'signup', component: UserComponent,
        children: [{ path: '', component: SignUpComponent }]
    },
    {
        path: 'login', component: SignInComponent
    },
    {
        path: 'menu', component: MenuComponent, canActivate:[AuthGuard] 
    },
    {
        path: 'consultarart', component: ConsultarArtComponent, canActivate:[AuthGuard] 
    },
    {
        path: 'pedidos', component: ConsultarPedidosComponent, canActivate:[AuthGuard] 
    },
    {
        path: 'viscanner', component: ViScannerComponent, canActivate:[AuthGuard] 
    },
    {
        path: 'pedidos/items', component: ConsultarPedidosItemComponent , canActivate:[AuthGuard] 
    },
    { path : '', redirectTo:'/login', pathMatch : 'full'},
    
    { path : 'estadocanchas', component: EstadocanchaComponent},

    { path : 'clubjuniors', redirectTo:'/clubjuniors/estadocanchas', pathMatch : 'full'},
     
];