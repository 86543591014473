import { Component, OnInit, Output ,EventEmitter} from '@angular/core';
import { UserService } from '../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
 
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
}) 



export class SignInComponent implements OnInit {
  isLoginError : boolean = false;

  @Output() event = new EventEmitter;

  constructor(private userService : UserService,private router : Router) { }
 
  ngOnInit() {
    localStorage.setItem('nombreusuario',"Vientri");
  }
 
  OnSubmit(userName,password){
    console.log("Sing-in on sumit ");
     this.userService.userAuthentication(userName,password).subscribe((data : any)=>{
      localStorage.setItem('x-access-token',data.token);
      localStorage.setItem('userToken',data.token);
      localStorage.setItem('nombreusuario',userName); 
      localStorage.setItem('idbasededatos',data.idbasededatos)
      localStorage.setItem('nombreempresa',data.nombreempresa)
      localStorage.setItem('sistema',data.sistema)
      localStorage.setItem('logoEmpresa',data.logoEmpresa)
      localStorage.setItem('idwsr',data.id)
      this.router.navigate(['/menu']);
      /*this.event.emit(userName);*/
    },
    (err : HttpErrorResponse)=>{
      this.isLoginError = true;
    });
  }
  
}
