import { Component, OnInit } from '@angular/core'; 
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable }    from '@angular/core';
 
import 'rxjs/add/operator/toPromise'; 
import {Ecancha } from './Ecancha.model';

@Component({
  selector: 'app-estadocancha',
  templateUrl: './estadocancha.component.html',
  styleUrls: ['./estadocancha.component.css']
})

export class EstadocanchaComponent implements OnInit {
  nombreUsuario: string;
  restItems: object[]; 
  restItemsUrl = 'http://vientri.mine.nu:3000/clubjuniors/estadocanchas';

  constructor(private http: HttpClient) { }

  
  ngOnInit() {  
    
    this.nombreUsuario=localStorage.getItem("nombreusuario")
    this.getCanchas();
  }
  
  getCanchas(){
    return this.http.get(this.restItemsUrl)
    .subscribe((data: Ecancha[]) => {
      this.restItems = data;
    })
  }
 
 
}