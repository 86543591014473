import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Response } from "@angular/http";
import { HttpHeaders } from "@angular/common/http";
import { Observable} from 'rxjs';
import { User } from './user.model'; 
import { of } from 'rxjs/observable/of';  
 
import { AppUserAuth } from './user.auth';
import { AppUserClaim } from './user.claim';
import { LOGIN_MOCKS } from './login-mocks';

@Injectable()

export class UserService {

  /*este es el puerto del server riocuarto para webapi */
  readonly rootUrl = 'http://vientri.mine.nu:3000';
  constructor(private http: HttpClient) { }
 

  securityObject: AppUserAuth = new AppUserAuth();
  
  registerUser(user : User){
    const body: User = {
      UserName: user.UserName,
      Password: user.Password,
      Email: user.Email,
      FirstName: user.FirstName,
      LastName: user.LastName
    }

    /*console.log("Registrando usuario :"+user.UserName+" pass:"+user.Password );*/
    /*,'No-Auth':'True'*/
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'});
    return this.http.post(this.rootUrl + '/api/auth/register', body);
  }

  userAuthentication(userName, password) {
    /*console.log("Authentication user:"+userName+" pass:"+password);*/
    /*,'No-Auth':'True'*/
    const body = new HttpParams().set('name', userName).set('password', password);  
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'});
    return this.http.post(this.rootUrl + '/api/auth/token',body.toString(), { headers: reqHeader });
  }

  getUserClaims(){
    var reqHeader = new HttpHeaders({ 'Access-Control-Allow-Origin': '*'});
    return  this.http.get(this.rootUrl+'/api/auth/getuserclaims',{ headers: reqHeader });
   }

   resetSecurityObject(): void {
    this.securityObject.userName = "";
    this.securityObject.bearerToken = "";
    this.securityObject.isAuthenticated = false;
    this.securityObject.claims = [];

    localStorage.removeItem("bearerToken");
  }

   login(entity: User): Observable<AppUserAuth> {
    // Initialize security object
    this.resetSecurityObject();

    // Use object assign to update the current object
    // NOTE: Don't create a new AppUserAuth object
    //       because that destroys all references to object
    Object.assign(this.securityObject, LOGIN_MOCKS.find(user => user.userName.toLowerCase() === entity.UserName.toLowerCase()));

    if (this.securityObject.userName !== "") {
      // Store into local storage
      localStorage.setItem("bearerToken",
        this.securityObject.bearerToken);
    }

    return of(this.securityObject);
  }

  logout(): void {
    this.resetSecurityObject();
  }

  private isClaimValid(claimType: string, claimValue?: string) {
    let ret: boolean = false;
    let auth: AppUserAuth = null;

    // Retrieve security object
    auth = this.securityObject;
    if (auth) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(":") >= 0) {
        let words: string[] = claimType.split(":");
        claimType = words[0];
        claimValue = words[1];
      }
      else {
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : "true";
      }
      // Attempt to find the claim
      ret = auth.claims.find(c => c.claimType == claimType && c.claimValue == claimValue) != null;
    }

    return ret;
  }

  hasClaim(claimType: any, claimValue?: any) {
    return this.isClaimValid(claimType, claimValue);
  }
}
