import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {
  public nombreUsuario:string;

  constructor() { 

   this.nombreUsuario="Vientri";
  } 

 ngOnInit() {
   this.nombreUsuario=localStorage.getItem("nombreusuario")
 } 

}
