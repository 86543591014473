import { Component, OnInit , ViewChild} from '@angular/core'; 
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';  
import { MatSort, MatTableDataSource } from '@angular/material';
import { PedidosItem } from './pedidositem';
import { ConsultarPedidos } from '../Consultar-Pedidos/Consultar-Pedidos';
import { ConsultarArt } from '../consultar-art/consultar-art'; 
@Component({
  selector: 'app-consultar-pedidos-item',
  templateUrl: './consultar-pedidos-item.component.html',
  styleUrls: ['./consultar-pedidos-item.component.css']
})

export class ConsultarPedidosItemComponent implements OnInit {
  public  id: string;
  public identificacion: string; 
  public codigoArticulo: string; 
  public cantidadArticulo: any; 
  public nombreUsuario: string;
  public idcolormodelo: string;

  restPedido: object[];
  restItems: object[]; 
  /*Consulta*/
  restPedidoUrl = 'http://vientri.mine.nu:3000/pedidos/id';
  restItemsUrl ='http://vientri.mine.nu:3000/pedidos/items';
  restBusquedaArtUrl = 'http://vientri.mine.nu:3000/articulos/busqueda';
  
  /*Alta*/
  cargarItemsUrl = 'http://vientri.mine.nu:3000/pedidos/cargaritem';
  
  /*Cambiar estados*/ 
  deleteUrl = 'http://vientri.mine.nu:3000/pedidos/cambiarestado';
  deleteItemUrl = 'http://vientri.mine.nu:3000/pedidos/borraritem';

  constructor(private http: HttpClient,private router : Router) {
      this.id='1'; 
      this.idcolormodelo="0"
      this.identificacion=""; 
      this.codigoArticulo="";
      this.cantidadArticulo=1;
   } 
/*'Id', 'Art__Cod',*/
   displayedColumns: string[] = [ 'Art__Des', 'Cantidad','Art__Impo','Acciones'];
   dataSource = new MatTableDataSource(this.restItems);
  
   @ViewChild(MatSort) sort: MatSort;
 

  ngOnInit() { 
    this.nombreUsuario=localStorage.getItem("nombreusuario")
    this.CargarPedido(); 
  } 

  CargarPedido(){ 
    var token=localStorage.getItem('x-access-token') 
    var pedidoid=localStorage.getItem('pedidoid')
    this.id=pedidoid 
    
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });

    return this.http.get(this.restPedidoUrl+"?id="+this.id , {headers: reqHeader})
    .subscribe((data: ConsultarPedidos[]) => {
      console.log(data);
      this.restPedido=data;
      this.identificacion=data[0].IdentificacionCliente;
      this.id=data[0].Id.toString(); 
      this.CargarItemsPedido();
    }) 
  }
  
  CargarItemsPedido(){
    var token=localStorage.getItem('x-access-token')
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });

    return this.http.get(this.restItemsUrl+"?id="+this.id , {headers: reqHeader})
    .subscribe((data: PedidosItem[]) => {
      this.dataSource = new MatTableDataSource(data);
      this.restItems = data;
    })
  }

  EliminarPedido(){
    var token=localStorage.getItem('x-access-token')
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });
    return this.http.post(this.deleteUrl+"?idpedido="+this.id+"&idestado=2","" , {headers: reqHeader})
    .subscribe((data:any) => { 
        this.router.navigate(['/pedidos']); 
    })
  } 

  ConfirmarPedido(){ 
    var token=localStorage.getItem('x-access-token')    
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });

    return this.http.post(this.deleteUrl+"?idpedido="+this.id+"&idestado=3","" , {headers: reqHeader})
    .subscribe((data:any) => { 
        this.router.navigate(['/pedidos']); 
    })
  } 

  EliminarPedidoItem(oItem){
    var token=localStorage.getItem('x-access-token')
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });
    return this.http.post(this.deleteItemUrl+"?id="+oItem.Id+"&idpedido="+this.id,"" , {headers: reqHeader})
    .subscribe((data:any) => { 
      this.CargarItemsPedido();
      /*  this.router.navigate(['/pedidos/id?id='+this.id]); 
      */
    })
  } 
  
  RegistrarNuevoItem(Formulario ){
    var token=localStorage.getItem('x-access-token') 
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var idwsr=localStorage.getItem('idwsr')
  
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });

    return this.http.get("http://vientri.mine.nu:3000/articulos/busqueda?identificador="+this.codigoArticulo  , {headers: reqHeader})
      .subscribe((data: ConsultarArt[]) => { 
          var tmpBusca: object;
          tmpBusca=data;
          if (tmpBusca[0].id==0 || tmpBusca[0].id==null)
            {
                console.log("sin resultado");
            }
          else{
            var reqHeaderAlta = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos ,'idwsr':idwsr });

            this.http.post(this.cargarItemsUrl+"?idpedido="+this.id+"&idart="+data[0].id+"&cantidad="+this.cantidadArticulo+"&idcolormodelo="+this.idcolormodelo ,"", {headers: reqHeaderAlta})
            .subscribe((data:any) => {
                console.log("vuelta insert item"+data)
                console.log("articulo encontrado"+tmpBusca)
                this.restItems.push({ 
                  Id: data.id
                  ,IdPedido:this.id
                  ,IdBasededatos:idbasededatos
                  ,IdArt: tmpBusca[0].id
                  ,Cantidad: this.cantidadArticulo 
                  ,IdColormodelo:0
                  ,IdUsrw_vendedor:0
                  ,Art__Cod: tmpBusca[0].cod
                  ,Art__Des: tmpBusca[0].des 
                  ,Art_Impo: tmpBusca[0].des
                }) 

                Formulario.controls.CodigoArticulo.reset() ;
                Formulario.controls.CantidadArticulo=1;
                this.cantidadArticulo=1;
                this.dataSource = new MatTableDataSource(this.restItems);
                /*  this.router.navigate(['/pedidos/items']);*/
               this.CargarItemsPedido(); 
              } 
            )
            /*
            this.restItems = data;
            this.dataSource = new MatTableDataSource(this.restItems);
            this.dataSource.sort = this.sort;*/
            }
        }
      );
    }; 
}
 
    