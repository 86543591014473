import { Component, OnInit } from '@angular/core';
import { User } from '../shared/user.model';
import { NgForm } from '@angular/forms';
import { UserService } from '../shared/user.service';
import { HttpErrorResponse } from '@angular/common/http'; 
import { Router } from '@angular/router'; 
 
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {
  user: User;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  isRegistracionError=false;

  constructor(private userService: UserService, private router : Router) { }
 
  ngOnInit() {
    this.resetForm();
  }
 
  resetForm(form?: NgForm) {
    if (form != null)
      form.reset();
    this.user = {
      UserName: '',
      Password: '',
      Email: '',
      FirstName: '',
      LastName: ''
    }
  }


 
  OnSubmit(form: NgForm) {
    console.log("Sing-up registra usuario on sumit ");
    this.userService.registerUser(form.value)
      .subscribe((data: any) => { 
          /*localStorage.setItem('userToken',data.access_token);*/
          localStorage.setItem('userToken',data.token);
          this.router.navigate(['/estadocanchas']);
        }, 
        (err : HttpErrorResponse)=>{
          this.isRegistracionError = true;
        });
      };
  
}