import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';
/*
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
*/
import { NgModule } from '@angular/core';

import { FormsModule} from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { UserService } from './user/shared/user.service';
import { AppComponent } from './app.component';
import { EncabezadoComponent } from './encabezado/encabezado.component';
import { PieComponent } from './pie/pie.component';
import { MenuComponent } from './menu/menu.component';
import { HttpClientModule } from '@angular/common/http';
import { SqlService } from './sql.service'; 
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { SignUpComponent } from './user/sign-up/sign-up.component';
import { RouterModule } from '@angular/router'
import { appRoutes } from './ruotes';
import { MatCheckboxModule } from '@angular/material';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button'; 
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material'; 
import { TruncatePipe } from './pipes/truncate.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { AuthGuard } from './auth/auth.guard';
import { EstadocanchaComponent } from './clubjuniors/estadocancha/estadocancha.component';
import { ConsultarArtComponent } from './vientri/consultar-art/consultar-art.component';
import { ConsultarPedidosComponent } from './vientri/consultar-pedidos/consultar-pedidos.component';
import { ConsultarPedidosItemComponent } from './vientri/consultar-pedidos-item/consultar-pedidos-item.component';
import { BCScannerComponent } from './bcscanner/bcscanner.component';
import { ViScannerComponent } from './vientri/vi-scanner/vi-scanner.component'; 

@NgModule({
  declarations: [
    AppComponent,
    EncabezadoComponent,
    PieComponent,
    MenuComponent, 
    UserComponent,
    SignInComponent,
    SignUpComponent,
    EstadocanchaComponent,
    ConsultarArtComponent,
    ConsultarPedidosComponent,
    ConsultarPedidosItemComponent, 
    TruncatePipe,
    CapitalizePipe,
    BCScannerComponent,
    ViScannerComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule, 
    MatCheckboxModule,
    MatExpansionModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    BarecodeScannerLivestreamModule
  ],
  providers: [SqlService,
    UserService,
    AuthGuard],
  bootstrap: [AppComponent
  ]
})
export class AppModule { }
