import { Component, OnInit , ViewChild} from '@angular/core'; 
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';  
import { MatSort, MatTableDataSource } from '@angular/material';
import { LecturasCbarra } from './lecturascbarra';

@Component({
  selector: 'app-vi-scanner',
  templateUrl: './vi-scanner.component.html',
  styleUrls: ['./vi-scanner.component.css']
})
export class ViScannerComponent implements OnInit {

  public identificacion: string; 

  nombreUsuario: string;
    
  
  restItems: object[]; 
  /*Consulta*/
  restLecturasCbarraUrl = 'http://vientri.mine.nu:3000/scanner/consultar';
  
  /*Alta*/
  cargarLecturasCbarraUrl = 'http://vientri.mine.nu:3000/scanner/registrar';
  
  /*Cambiar estados*/ 
  borrarLecturasCbarraUrl = 'http://vientri.mine.nu:3000/scanner/borrar';

  constructor(private http: HttpClient,private router : Router) {
    this.identificacion=""; 
 } 

 displayedColumns: string[] = [ 'Codigo','Acciones'];
 dataSource = new MatTableDataSource(this.restItems);

 @ViewChild(MatSort) sort: MatSort;


  ngOnInit() {
    this.nombreUsuario=localStorage.getItem("nombreusuario")
    this.CargarLecturas();  

  }

  CargarLecturas(){
    var token=localStorage.getItem('x-access-token')
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var idwsr=localStorage.getItem('idwsr')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos ,'idwsr':idwsr });

    return this.http.get(this.restLecturasCbarraUrl , {headers: reqHeader})
    .subscribe((data: LecturasCbarra[]) => {
      this.dataSource = new MatTableDataSource(data);
      this.restItems = data;
    })
  }



  RegistrarLectura(Formulario ){
    var token=localStorage.getItem('x-access-token') 
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var idwsr=localStorage.getItem('idwsr')

    var reqHeaderAlta = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos ,'idwsr':idwsr });

    return this.http.post(this.cargarLecturasCbarraUrl+"?cod="+this.identificacion ,"", {headers: reqHeaderAlta}).subscribe((data:any) => {
              console.log("vuelta insert lecturascbarra"+data)  
              this.restItems.push({ 
                Id: data.id
                ,Codigo:this.identificacion
                ,Utransac:'V'
                ,IdUsrw: idwsr 
              }) 

              Formulario.controls.Identificacion.reset() ;
              this.identificacion="";
              this.dataSource = new MatTableDataSource(this.restItems);
              this.CargarLecturas(); 
            } 
          ); 
  }

 
  EliminarLectura(oItem){
    var token=localStorage.getItem('x-access-token')
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var idwsr=localStorage.getItem('idwsr')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos ,'idwsr':idwsr });
    return this.http.post(this.borrarLecturasCbarraUrl+"?id="+oItem.Id ,"" , {headers: reqHeader})
    .subscribe((data:any) => { 
      this.CargarLecturas(); 
    })
  } 
}
