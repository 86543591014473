import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Mnu} from './Mnu'; 

@Injectable({
  providedIn: 'root'
})
export class SqlService {
 
    constructor(private httpClient: HttpClient) {
      console.log("el servicio esta funcionando");
   }

   getValor(){
    return this.httpClient.get("https://jsonplaceholder.typicode.com/posts");
   }

  
   getMenu(){
    return this.httpClient.get<Mnu[]>("https://localhost:8080/Menu");
   }

  }
