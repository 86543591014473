import { Component, OnInit ,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConsultarPedidos } from './consultar-pedidos';
import { HttpHeaders } from "@angular/common/http"; 
import { MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consultar-pedidos',
  templateUrl: './consultar-pedidos.component.html',
  styleUrls: ['./consultar-pedidos.component.css']
})

export class ConsultarPedidosComponent implements OnInit {
  public  id: string;
  public identificacion: string;
  restItems: object[]; 
  nombreUsuario: string;
  restItemsUrl = 'http://vientri.mine.nu:3000/pedidos/activos';
  nuevoPedidoUrl = 'http://vientri.mine.nu:3000/pedidos/registrar';
  deleteUrl = 'http://vientri.mine.nu:3000/pedidos/cambiarestado';

  constructor(private http: HttpClient,private router : Router) {
    this.identificacion="";
    this.id=localStorage.getItem('idwsr');
   }

   displayedColumns: string[] = ['Id', 'IdentificacionCliente', 'Total','Acciones'];
   /*displayedColumns: string[] = ['Id', 'Fecha', 'IdentificacionCliente', 'Estado__Des','Total','Acciones'];
   */
   
   dataSource = new MatTableDataSource(this.restItems);
 
   @ViewChild(MatSort) sort: MatSort;


  ngOnInit() { 
    this.nombreUsuario=localStorage.getItem("nombreusuario")
    this.CargarPedidos(this.id,1)
  }
  
  RegistrarNuevoPedido(identificacion,Formulario){
    var token=localStorage.getItem('x-access-token')  
    var sistema=localStorage.getItem('sistema')
    var idwsr=localStorage.getItem('idwsr')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos ,'idwsr':idwsr });

    return this.http.post(this.nuevoPedidoUrl+"?id="+idwsr+"&Identificacion="+identificacion ,"", {headers: reqHeader})
    .subscribe((data:ConsultarPedidos[]) => {
      console.log("Vuelta de Registrar nuevo pedido "+data);
      localStorage.setItem('pedidoid',data.toString());
      this.router.navigate(['/pedidos/items']);
/*
      Formulario.controls.Identificacion.reset() ;
      this.restItems.push({
        Id: data.id
        ,IdBasededatos:54
        ,Fecsys: new Date(),
    Fecins: new Date(),
    Utransac: 'V',
    IdEstado: 1,
    IdUsrw_vendedor: this.id,
    IdPer_cliente:0,
    IdentificacionCliente: identificacion,
    Fecha: new Date(),
    Total:0,
    Estado__Des:'Pendiente'
      })
      this.dataSource = new MatTableDataSource(this.restItems);
      this.CargarPedidos(this.id,1);
 */
    })
  }
  ConsultarPedido(oItem){
    console.log("consultarPedido id:"+oItem.Id);
    localStorage.setItem('pedidoid',oItem.Id);
    this.router.navigate(['/pedidos/items']);
  }

  EliminarPedido(oItem){
    var token=localStorage.getItem('x-access-token')
    var reqHeader = new HttpHeaders({ 'x-access-token': token });
    return this.http.post(this.deleteUrl+"?idpedido="+oItem.Id+"&idestado=2","" , {headers: reqHeader})
    .subscribe((data:any) => {
      var IndiceaBorrar=this.restItems.indexOf(oItem);
      console.log("Indice encontrado: "+IndiceaBorrar);
      if (IndiceaBorrar>=0)
        {
          this.restItems.slice(IndiceaBorrar,1)
          this.dataSource = new MatTableDataSource(this.restItems);
        }
      this.CargarPedidos(this.id,1);
      })
    } 
  
  CargarPedidos(id,idestado){
    var token=localStorage.getItem('x-access-token')     
    var sistema=localStorage.getItem('sistema')
    var idbasededatos=localStorage.getItem('idbasededatos')
    var reqHeader = new HttpHeaders({ 'x-access-token': token, 'sistema': sistema,'idbasededatos': idbasededatos  });

    return this.http.get(this.restItemsUrl+"?id="+id+"&idestado="+idestado , {headers: reqHeader})
    .subscribe((data: ConsultarPedidos[]) => {
      
       this.dataSource = new MatTableDataSource(data);
      this.restItems = data;
      this.dataSource.sort = this.sort;
     /* window.alert(this.restItems.toString);
      console.log(this.dataSource);
      */
    })
  } 
}
