import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Injectable }    from '@angular/core';
import { HttpHeaders } from "@angular/common/http";

import 'rxjs/add/operator/toPromise'; 
import { Mnu } from '../Mnu'; 

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})


export class MenuComponent implements OnInit {
  restItems: object[]; 
  restItemsUrl = 'http://vientri.mine.nu:3000/vientri/menues';
  nombreUsuario="";

  constructor(private http: HttpClient) { 
    
  }


  ngOnInit() {  
    this.nombreUsuario=localStorage.getItem("nombreusuario");
    this.getMenues();
  }

  getMenues(){
    var token=localStorage.getItem('x-access-token')
    var reqHeader = new HttpHeaders({ 'x-access-token': token });
    return this.http.get(this.restItemsUrl+"?id=1", {headers: reqHeader})
    .subscribe((data: Mnu[]) => {
      this.restItems = data;
    })
  }
};

